(function (angular) {
    angular.module('MyHippoProducer.Controllers', []).controller('MainController', MainController);
    MainController.$inject = ['$rootScope', '$scope', '$state', '$window', '$location', 'APIService', '$cookies', 'HeapService'];
    function MainController ($rootScope, $scope, $state, $window, $location, APIService, $cookies, heapService) {
        $scope.logout = function () {
            APIService.logout().then(() => {
                $rootScope.$broadcast('user.logout');
            });
        };

        $scope.$on('user.login', function (event, params) {
            const getLocationHostName = () => window.location.hostname;
            const getDomainName = () => getLocationHostName().split('.').slice(-2).join('.');
            const getCookieOptions = () => ({domain: '.' + getDomainName(),});
            if(params.token) {
                $cookies.put('hippo-agent-jwt', params.token, getCookieOptions());
            }

            if (params.redirectUrl) {   
                $window.location.href = params.redirectUrl;
            } else {
                $state.transitionTo('portal.policiesList', null, {reload: true});
            }
        });

        $scope.$on('user.logout', function () {
            const getLocationHostName = () => window.location.hostname;
            const getDomainName = () => getLocationHostName().split('.').slice(-2).join('.');
            const getCookieOptions = () => ({domain: '.' + getDomainName(),});
            $cookies.remove('hippo-agent-jwt', getCookieOptions());
            $cookies.remove('hippo-producer-jwt', getCookieOptions());
            heapService.resetIdentity();
            const reactProducerDomian = window.REACT_PRODUCER_DOMAIN;
            const protocol = $location.protocol();
            $window.location.href = `${protocol}://${reactProducerDomian}/login`;
        });
    }

    angular.module('MyHippoProducer.Controllers').controller('HomeController', HomeController);
    HomeController.$inject = ['$log', '$scope', '$q', '$stateParams', 'angularAuth0', 'APIService', '$timeout'];
    function HomeController ($log, $scope, $q, $stateParams, angularAuth0, APIService, $timeout) {
        $log.info('HomeController', $stateParams);

        if ($stateParams.accessToken && $stateParams.producerId) {
            APIService.loginWithAccessToken($stateParams.accessToken, $stateParams.producerId).then(response => {
                const expandToken = _.assignIn(response, {'token': response.token});
                $scope.$emit('user.login', expandToken);
            });
        }
        $scope.isLoggingIn = false;
        $scope.login = function () {
            if (!$scope.isLoggingIn) {
                $scope.isLoggingIn = true;
                return $q((resolve, reject) => {
                    angularAuth0.popup.loginWithCredentials({
                        connection: 'Username-Password-Authentication',
                        username: $scope.email,
                        password: $scope.password,
                        scope: 'openid email user_id'
                    }, (err, token) => {
                        if (err) return reject(err);
                        resolve(token);
                    });
                }).then(token => {
                    return APIService.login(token.idToken);
                }).then(response => {
                    $scope.isLoggingIn = false;
                    $scope.$emit('user.login', response);
                }).catch(err => {
                    $timeout(() => { $scope.isLoggingIn = false; }, 100);
                    if (err) {
                        if (err.code === 'unauthorized' && err.error_description === 'user is blocked') {
                            $scope.errorMessage = 'User is blocked. Please contact your system admin.';
                            return $scope.errorMessage;
                        } else if (err.status > 399 && err.status < 500) {
                            $scope.errorMessage = 'Unknown user or wrong password. Please check your credentials and try again';
                            return $scope.errorMessage;
                        }
                        $scope.errorMessage = err.error_description || err.message;
                        return $scope.errorMessage;
                    }
                });
            }
        };
    }

    angular.module('MyHippoProducer.Controllers').controller('ForgotPasswordController', ForgotPasswordController);
    ForgotPasswordController.$inject = ['$log', '$scope', '$stateParams', '$location', '$window'];
    function ForgotPasswordController ($log, $scope, $stateParams, $location, $window) {
        $log.info('ForgotPasswordController', $stateParams);
        if (window.PRODUCER_WITH_REACT) {
            const reactProducerDomian = window.REACT_PRODUCER_DOMAIN;
            const protocol = $location.protocol();
            $window.location.href = `${protocol}://${reactProducerDomian}/forgot-password`;
        } else {
            $scope.resetPassword = function () {
                $log.info('resetPassword');
                angularAuth0.changePassword({
                    connection: 'Username-Password-Authentication',
                    email: $scope.email
                }, function (err, message) {
                    $log.info(err, message);
                    if (err) {
                        $scope.successMessage = null;
                        $scope.errorMessage = err.message;
                    } else {
                        $scope.errorMessage = null;
                        $scope.successMessage = message;
                    }
                });
            };
        }
    }

    angular.module('MyHippoProducer.Controllers').controller('PortalController', PortalController);

    function PortalController ($log) {
        $log.info('PortalController');
    }
})(window.angular, window._);
